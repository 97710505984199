import React, { useContext } from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { useTheme } from 'styled-components';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {
  contentfulRichTextLinks,
  contentfulRichTextInlineImage,
  contentfulRichTextBlockquoteWithQuoteSVG,
  getSlugByID,
} from "../../../utils/helpers";
import QuotationMarksIcon from "../../common/QuotationMarksIcon";
import ArrowIcon from "../../common/ArrowIcon";
import { SettingsContext } from "../../../context/SettingsContext";
import S from "./styles";
import { ContentfulClientExperience } from "../../../types";
import { theme } from "../../../styles/theme";

export interface ClientExperienceFooterProps
  extends ContentfulClientExperience {
  pathContext?: any;
  [prop: string]: any;
}

const ClientExperienceFooter = ({
  quote,
  colourScheme,
  pathContext,
  ...rest
}: ClientExperienceFooterProps) => {
  const {
    settings: { clientExperienceSharedContent, clientExperiencesPage },
  } = useContext(SettingsContext);
  const { pageMap } = pathContext;
  const theme = useTheme();

  return (
    <S.ClientExperienceFooter colourScheme={colourScheme} {...rest}>
      <div className="content">
        {clientExperienceSharedContent &&
          documentToReactComponents(clientExperienceSharedContent.json, {
            renderNode: {
              ...contentfulRichTextLinks({ pageMap }),
              ...contentfulRichTextInlineImage,
              ...contentfulRichTextBlockquoteWithQuoteSVG({
                colourScheme: "dark",
              }),
            },
          })}
        <hr />
        {quote && (
          <blockquote>
            <QuotationMarksIcon colourScheme="light" />{quote.quote}
          </blockquote>
        )}
        {clientExperiencesPage && (
          <div className="links">
            <AniLink
              cover
              direction="up"
              bg={theme.colors.darkGrey1}
              to={getSlugByID({
                pageMap: pageMap,
                pageID: clientExperiencesPage.contentful_id,
              })}
            >
              See All Experiences <ArrowIcon colourScheme="light" />
            </AniLink>
          </div>
        )}
      </div>
    </S.ClientExperienceFooter>
  );
};

export default ClientExperienceFooter;
