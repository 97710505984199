import styled from 'styled-components';

const ClientExperienceHeader = styled.div`
    position: relative;
    padding: 200px 0 0 0;

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 19px;
        line-height: 30px;
        border-bottom: 1px solid ${({ theme }) => theme.colors.mediumGrey1};
        padding-bottom: 20px;
        text-transform: uppercase;
        letter-spacing: 1.8px;

        .title {
            font-family: ${({ theme }) => theme.fonts.rajdhaniSemiBold};

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                font-size: 18px;

                &.back {
                    display: none;
                }
            }
        }

        a {
            display: flex;
            align-items: center;
            font-family: ${({ theme }) => theme.fonts.rajdhaniSemiBold};
            text-decoration: none;

            svg {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
        }
    }

    .bottom {
        padding-top: 40px;
        display: flex;
        flex-wrap: wrap;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
            flex-direction: column;
        }

        > div {
            position: relative;
            width: 33.3333%;

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                width: 50%;
            }

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                width: 100%;
            }

            .title {
                font-family: ${({ theme }) => theme.fonts.rajdhaniSemiBold};
                text-transform: uppercase;
                font-size: 19px;
                line-height: 30px;
                letter-spacing: 1.8px;

                @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                    font-size: 18px;
                }
            }

            h4 {
                font-size: 20px;
                line-height: 33px;
                font-family: ${({ theme }) => theme.fonts.poppinsSemiBold};
                padding-right: 20px;

                @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                    margin-top: 0;
                }

                @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                    font-size: 16px;
                    line-height: 26px;
                    margin-top: 0;
                    margin-bottom: 30px;
                    padding-right: 0;
                }
            }
        }
    }

    .gatsby-image-wrapper {
        max-height: 700px;
        width: 100%;

        img {
            object-fit: cover;
            object-position: center;
        }
    }
`

export default {
    ClientExperienceHeader
}