import styled from 'styled-components';
import { ClientExperienceFooterProps } from './'

const ClientExperienceFooter = styled.div<ClientExperienceFooterProps>`
    position: relative;
    padding: 0 0 200px 0;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        padding: 0 0 100px 0;
    }

    .content {
        position: relative;
        width: 66.6666%;
        margin-left: auto;
        white-space: pre-wrap;
        font-size: 20px;
        line-height: 33px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
            width: 100%;
        }

        h4 {
            margin-top: 0;
            margin-bottom: 30px;
            font-size: 46px;
            line-height: 52px;
            letter-spacing: -1px;
        }

        h5 {
            margin-top: 0;
            font-family: ${({ theme }) => theme.fonts.helveticaRegular};
            font-size: 32px;
            line-height: 46px;
            letter-spacing: -0.6px;
        }

        h6 {
            margin-top: 0;
            font-size: 24px;
            line-height: 36px;
            font-family: ${({ theme }) => theme.fonts.helveticaRegular};
        }

        img {
            max-width: 100%;
        }
        
        p {
            position: relative;
            display: block;
            margin: 0;
            padding: 10px 0;

            &:first-of-type {
                padding-top: 0;
            }

            &:last-of-type {
                padding-bottom: 0;
            }

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                font-size: 16px;
                line-height: 26px;
            }
        }

        a {
            color: ${({ theme}) => theme.colors.darkGrey1};
        }

        b {
            font-family: ${({ theme }) => theme.fonts.poppinsSemiBold};
        }

        u {
            text-decoration: none;
            color: ${({ theme}) => theme.colors.green2};
        }

        ol {
            list-style: none;
            padding: 0;

            li {
                &:after {
                    position: relative;
                    content: "";
                    display: inline-block;
                    top: -5px;
                    width: 30px;
                    height: 2px;
                    background-color: ${({ theme }) => theme.colors.green2};
                }
            }
        }

        hr {
            margin: 60px 0;
            padding: 0;
            border: none;
            height: 1px;
            background-color: ${({theme }) => theme.colors.mediumGrey1};
        }

        blockquote {
            position: relative;
            font-family: ${({ theme }) => theme.fonts.helveticaRegular};
            font-size: 32px;
            line-height: 46px;
            letter-spacing: -0.6px;
            padding: 0;
            margin: 80px 0;

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                font-size: 20px;
                line-height: 30px;
                margin: 60px 0;
            }

            svg {
                position: absolute;
                top: 8px;
                left: -50px;

                @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                    left: 0;
                    top: 0;
                    position: relative;
                    display:block;
                    margin-bottom: 20px;
                }
            }
        }

        .links {
            border-top: 1px solid ${({theme }) => theme.colors.mediumGrey1};
            border-bottom: 1px solid ${({theme }) => theme.colors.mediumGrey1};
            position: relative;
            flex: 1;
            margin: 40px 0;
            padding: 40px 0;
            color: ${({ theme }) => theme.colors.darkGrey1};
            
            @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                margin: 20px 0;
                padding: 10px 0;
            }

            a {
                font-family: ${({ theme }) => theme.fonts.rajdhaniSemiBold};
                text-decoration: none;
                text-transform: uppercase;
                font-size: 19px;
                line-height: 30px;
                letter-spacing: 1.8px;
                color: ${({ theme }) => theme.colors.darkGrey1};
                display: flex;
                justify-content: space-between;
                align-items: center;

                &:hover {
                    svg {
                        transform: translateX(0);
                    }
                }
                
                svg {
                    transition: transform 0.2s ease;
                    margin-left: 20px;
                    transform: translateX(-20px);
                    width: 40px;
                    height: 40px;

                    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                        width: 30px;
                        height: 30px;
                        transform: translateX(0);
                    }
                }
            }
        }
    }    
`

export default {
    ClientExperienceFooter
}