import styled from 'styled-components';

const Content = styled.div`
    .content-columns {
        &:not(:first-of-type) {
            padding-top: 0px;
        }

        &:last-of-type {
            padding-bottom: 20px;
        }

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
            padding-bottom: 20px;

            &:not(:first-of-type) {
                padding-top: 40px;
            }
        }
    }
`

export default {
    Content
}