import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import Meta from "../../components/content/Meta";
import Container from "../../components/layout/Container";
import ClientExperienceHeader from "../../components/content/ClientExperienceHeader";
import ClientExperienceFooter from "../../components/content/ClientExperienceFooter";
import contentfulComponentMap from "../../utils/contentfulComponentMap";
import { SettingsContext } from "../../context/SettingsContext";
import S from './styles';

export const query = graphql`
  query ClientExperienceQuery($contentful_id: String!) {
    pageData: contentfulClientExperience(
      contentful_id: { eq: $contentful_id }
    ) {
      engagement
      client
      sector
      slug
      number
      image {
        fluid(quality: 80, maxWidth: 1200) {
          ...GatsbyContentfulFluid_withWebp
        }
        fixed(quality: 80, width: 1200) {
          src
        }
      }
      excerpt {
        excerpt
      }
      content {
        ...HeadingContentColumns
      }
      quote {
        quote
      }
      metadata {
        ...Metadata
      }
    }
  }
`;

const ClientExperience = ({ data, pathContext }) => {
  const { setSettings } = useContext(SettingsContext);

  if (!data || !data.pageData) return null;

  const { content = [], metadata = {}, ...experience } = data.pageData;

  useEffect(() => {
    if (pathContext.settings) {
      setSettings(pathContext.settings);
    }
  }, []);

  return (
    <Layout pathContext={pathContext}>
      <Meta
        defaults={pathContext.settings.defaultMetadata}
        meta={{
          title: `${experience.client} | Client Experience - JVAT`,
          ogTitle: `${experience.client} | Client Experience - JVAT`,
          description: { description: experience.excerpt.excerpt },
          ogDescription: {ogDescription: experience.excerpt.excerpt },
          ogImage: experience.image,
          twitterImage: experience.image,
          twitterDescription: {twitterDescription: experience.excerpt.excerpt },
          ...metadata,
        }}
      />
      <Container>
        <ClientExperienceHeader pathContext={pathContext} {...data.pageData} />
      </Container>
      <S.Content>
        {content &&
          content.map((component, index) => {
          const Component = contentfulComponentMap[component?.internal?.type];

          if (!Component) return null;

          return (
            <Component key={index} {...component} pathContext={pathContext} />
          );
        })}
      </S.Content>
      <Container>
        <ClientExperienceFooter pathContext={pathContext} {...data.pageData} />
      </Container>
    </Layout>
  );
};

export default ClientExperience;
