import React, { useEffect, useContext } from "react";
import Image from "gatsby-image";
import { useTheme } from 'styled-components';
import ArrowIcon from "../../common/ArrowIcon";
import S from "./styles";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { HeaderContext } from "../../../context/HeaderContext";
import { SettingsContext } from "../../../context/SettingsContext";
import { ContentfulClientExperience } from "../../../types";

export interface ClientExperienceHeaderProps
  extends ContentfulClientExperience {
  [prop: string]: any;
}

const ClientExperienceHeader = ({
  number,
  sector,
  client,
  engagement,
  image,
  ...rest
}: ClientExperienceHeaderProps) => {
  const { setHeaderColor, setInitialHeaderColor } = useContext(HeaderContext);
  const { settings } = useContext(SettingsContext);
  const theme = useTheme();

  useEffect(() => {
    setHeaderColor("light");
    setInitialHeaderColor("light");
  }, []);

  return (
    <S.ClientExperienceHeader>
      <div className="top">
        <div className="title back">
          <AniLink cover direction="up" bg={theme.colors.darkGrey1} to={settings.clientExperiencesPageSlug}>
            <ArrowIcon
              style={{ transform: "rotate(180deg)" }}
              colourScheme="light"
            />{" "}
            Back
          </AniLink>
        </div>
        <div className="title">
          Client Experience _{number < 10 ? `0${number}` : number}
        </div>
      </div>
      <div className="bottom">
        <div className="sector">
          <div className="title">Sector</div>
          <h4>{sector}</h4>
        </div>
        <div className="client">
          <div className="title">Client</div>
          <h4>{client}</h4>
        </div>
        <div className="engagement">
          <div className="title">engagement</div>
          <h4>{engagement}</h4>
        </div>
      </div>
      <Image fluid={image.fluid} />
    </S.ClientExperienceHeader>
  );
};

export default ClientExperienceHeader;
